import proflepic from '../Image/AmanPic3.png'
import color from './color'
import logo from '../Icon/icon_192.png'
const Info = {
    menu: ['About', 'Expirence', 'Work', 'Contact'],
    name: "Aman Kumar",
    email: 'engamankumar@gmail.com',
    icon:logo,
    phrase: "I love to build new things.",
    briefInfo: "I’m a software engineer who constantly seeks out innovative solutions to everyday problems. I specialize in building applications specific to the business needs of my clients. I am currently working at ",
    // briefInfo: "I’m a software engineer specializing in building exceptional digital experiences. Currently, I’m focused on building accessible, human-centered products at ",
    companyName: "TCS",
    companyURL: "https://www.tcs.com/",
    about: <div>  Hello! My name is Aman and I enjoy creating and exploring new things. My interest in Application development started back in 2018. <br />
        Fast-forward to today, and I’ve had the privilege of working at 
        <span style={{ color: color.textGreen }}>  TCS</span>  a huge corporation,
        <span style={{ color: color.textGreen }}></span> <br />  I have done work in mobile app creation and front-end/back-end web development.
        My main focus these days is create best user Interface and application which can be rendered in any device for a variety of clients.
        {/* <br /> I recently worked on a project that helps user to genrate invoice using Node & React. */ }
        {/* <span style={{ color: color.textGreen }}> TCS</span> */}
        <br /> Here are a few technologies I’ve been working with recently: </div>,

    language: ['JavaScript (ES6+)', 'React', 'Python', 'UI5', 'Node.js', 'Redux'],
    proflepicUrl: proflepic,
    job: [
        {
            companyName: 'TCS',
            companyURL: "https://www.tcs.com/",
            year: 'Dec 2018 - Present',
            position:'IT Analyst',
            responsibility: ['Write modern, performant, maintainable code for a diverse array of client and internal projects',
             'Work with a variety of different languages, platforms, frameworks, and content management systems such as JavaScript, React, SAP UI5, Node.js and ABAP',
             'Meeting with the development team to discuss user interface ideas and applications.',
             'Worked with team and followed AGILE approach for Development of project.',
             'Deploying Application to App store.'
            ]
        },

    ],
    projectDetails: [
        {
            "name": 'MyShelf',
            "description": "MyShelf is a hybrid app which is used in Hospital and inventory to count the stock. Once counting completed as confirmation they receive an email with PDF.",
            "language": "React, NodeJS, JS, Cordova, Neptune DX platform."
        },
        {
            "name": 'FIMA',
            "description": "FIMA is a hybrid app which is used in Hospital to get surgery related data and the equipment details used in surgery.",
            "language": "React, NodeJS, JS, Cordova, Neptune DX platform."
        },
        {
            "name": 'ChatBot',
            "description": "The chatbot is build to get information from sap like getting sales order, purchase order details and stock related details. We build a chatbot where user can easily find all the detail without login in SAP.",
            "language": "React, NodeJS, VS Code"
        },
        {
            "name": 'Perfect Receipt',
            "description": "The application is build To generate receipt from SAP when product is delivered to inventory & user can also check the status of product or any damged product so they can raise concern.",
            "language": "React, NodeJS, JS, SAP"
        },
        {
            "name": 'JARVIS',
            "description": "A chatbot build to do multiple thning, It can solve simple calclation, forecaset wether, translate into multiple language and send email. ",
            "language": "Android Studio, Google Cloud, DialogFlow"
        },
        {
            "name": 'INSTANT APP',
            "description": "INSTANT APP, build to help people in emergency situation. This application send location to nearby people, those who recived the location can get direction with google map.",
            "language": "Android Studio, Google Cloud, DialogFlow"
        },
    ],
    contactHeader: 'Get In Touch',

    contact: 'I’m  currently looking for any new opportunities, my inbox is always open, whether you have a job opportunities or just want to say hi, I’ll try my best to get back to you!',
    emailbtn: 'Say Hello',

        github:'https://github.com/engamankumar',
        linkedIn :'https://www.linkedin.com/in/engamankumar',
        codeSandBox: 'https://codesandbox.io/u/iamamankumar',
        instagram : 'https://www.instagram.com/iamamankumar/',
        mobileNo:'+918181836606',
    
    footer:'Designed & Built by Aman Kumar'

}

export default Info;