// export const textGreen : "#64ffda",
// export const textWhite : "white",
// export const textdullWhite : '#8892b0',
// export const backgroundColor : '#0a1930',
// export const lightBlue :'#112240',
// export const lightestBlue : '#ccd6f6',
// export const lightSlate : '#a8b2d1',
// export const slate : '#8892b0'

const color ={

    textGreen : "#64ffda",
    textWhite : "white",
    textdullWhite : '#8892b0',
    backgroundColor : '#0a1930',
    lightBlue :'#112240',
    lightestBlue : '#ccd6f6',
    lightSlate : '#a8b2d1',
    slate : '#8892b0',
    whiteLightMenu :'#eff8fe',
    drawerMenuColor : '#ccd5ed'
}
export default color;