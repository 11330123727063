import { Typography } from '@mui/material'
import React from 'react'
import color from '../utils/color';
import '../utils/Main.css'
import Info from '../utils/ResumeData';
import font from '../utils/font';

function Fotter() {
    return (
        <div style={{marginTop:'10%'}}>
            <Typography  className='footer' sx={{color:color.lightSlate, textAlign:'center',fontSize:'13px',fontFamily: font.monospace}}>
             {Info.footer}
        </Typography>
        </div>
    )
}

export default Fotter
