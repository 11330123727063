import { Card, CardContent, CardHeader, Typography, Box } from '@mui/material'
import React, { useState } from 'react'
import '../utils/Main.css'
import color from '../utils/color';
import FolderIcon from '@mui/icons-material/Folder';

function ProjectDetails(props) {
    const header = (name) => {
        return <span>{name}
            <FolderIcon />
        </span>
    }
    const [shadow, setShadow] = useState(1)

    const onMouseOver = () => setShadow(10);

    const onMouseOut = () => setShadow(1);
    return (
        <Box sx={{ bgcolor: color.backgroundColor }} >

            <Card  onMouseOver={() => onMouseOver()}
                onPointerOut={() => onMouseOut()}
                raised={shadow} sx={{ height: '100%', fontSize: '3em', bgcolor: color.lightBlue }}>
                <CardHeader className='projectTitle' title={props.name} component='span' sx={{ color: color.lightestBlue, fontWeight: 'bold', fontSize: '2em' }} style={{ fontWeight: 'bold' }}>
                    <FolderIcon />
                </CardHeader>
                <CardContent sx={{ color: color.lightSlate, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography sx={{ flexGrow: 1, height: '120px' }}>
                            {props.description}
                        </Typography>
                    </Box>
                    <div style={{ textAlign: 'center' }} >
                        <Typography sx={{ color: color.slate, fontSize: '12px' }}>
                            {props.language}
                        </Typography>
                    </div>
                </CardContent>

            </Card>
        </Box>
    )
}

export default ProjectDetails
