import { Box, Link, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import color from '../utils/color';
import font from '../utils/font';
import '../utils/Main.css'
import Info from '../utils/ResumeData';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Expirence(props) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div id="job" style={{ paddingTop: '10%', marginTop: '5%' }}>
            <Typography edge="start" variant="h6" component="div" sx={{ color: color.textWhite ,pt:20}}>
                <span style={{ color: color.textGreen, fontFamily: font.monospace }}>02.</span> Where I’ve Worked
            </Typography>
            <Box sx={{ flexGrow: 1, bgcolor: color.backgroundColor, display: 'flex', mt: 3, flexDirection: props.isMobile ? 'column' : 'row' }}>
                <Tabs
                    className="tabs"
                    value={value}
                    orientation={props.isMobile ? 'horizontal' : 'vertical'}
                    variant='scrollable'
                    onChange={handleChange}

                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    indicatorColor="secondary"
                    sx={{ borderRight: 1, borderColor: '#0f2444', color: "#233554", display: 'table' }}>

                    {Info.job.map((data, index) => {
                        return <Tab label={data.companyName} key={index} sx={{ fontSize: '1em' }} key={data.companyName}{...a11yProps(index)} />
                    })}
                </Tabs>
                {Info.job.map((data, index) => {
                    return <TabPanel value={value} index={index} key={index}>
                        <div className='expirence' style={{ color: color.textdullWhite }}>

                            <span style={{ color: color.textWhite, fontWeight: 'bolder', fontSize: '22px' }}>{data.position} </span>
                            <Link href={data.companyURL} underline='none' style={{ color: color.textGreen, fontWeight: 'bolder', fontSize: '22px' }}> @ {data.companyName}  </Link>
                            <br />{data.year}
                            <ul>
                                {data.responsibility.map((jRes, indexJ) => <li key={indexJ}>{jRes}</li>)}
                            </ul>

                        </div>
                    </TabPanel>
                })}

            </Box>
        </div>
    )

}
