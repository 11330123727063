import { Box, Card, CardActions, CardMedia, Typography } from '@mui/material';
import React from 'react';
import '../utils/Main.css'
import  Info from '../utils/ResumeData'
import  font from '../utils/font'
import color from '../utils/color'
export default function AboutMe(props) {
    return (
        <Box id="about" style={{marginLeft: "3%",marginTop:'5%',}}>
            <Typography edge="start" variant="h6" component="div" sx={{ pt:20,color: color.textWhite }}>
                <span style={{ color: color.textGreen, fontFamily: font.monospace }}>01.</span> About Me
            </Typography>
            <Box sx={{ display: "flex", flexDirection: (props.isMobile) ? 'column' : 'row', mt: 6 }}>
                <Box sx={{ width: (props.isMobile) ? '100%' : "40%" }}>
                    <Typography edge="start" variant="h6" component="div" sx={{ color: color.textdullWhite, fontSize: '2vh' }} >
                    {Info.about}
                    </Typography>
                    <ul className="skills-list">
                       {Info.language.map((lang)=><li key={lang}>{lang}</li>)}
                            </ul>
                </Box>
                <Box sx={{ height: "100%", border: "2px solid #64ffda", ml: "10%", mt: (props.isMobile) ? "10%" : '' }} >
                <Card className= 'cardRoot' sx={{ width: (props.isMobile) ? "100%" : "40vh" }} >
                    <CardActions className= 'card'>
                    <CardMedia  component="img"
                                image={Info.proflepicUrl}
                                 alt={Info.name}
                             />
                    </CardActions>
                </Card>
                </Box>
            </Box>
        </Box>
    )

}