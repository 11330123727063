import React from 'react'
import { IconButton } from '@mui/material'
import color from '../utils/color'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import CodeIcon from '@mui/icons-material/Code';
import GitHubIcon from '@mui/icons-material/GitHub';
import Info from '../utils/ResumeData';

import '../utils/Main.css'
function LeftPanel() {
    return (
        <div style={{ color: color.lightSlate, width: '35%', textAlign: 'right' }}>
            <div className='horizontalLine1 ' style={{ color: color.lightSlate, width: '35%', textAlign: 'right' }}>

                {Info.github && <IconButton href={Info.github} target='_blank'>
                    <GitHubIcon sx={{ color: color.lightSlate }} />
                </IconButton>}

                {Info.linkedIn && <IconButton href={Info.linkedIn} target='_blank'>
                    <LinkedInIcon sx={{ color: color.lightSlate }} />
                </IconButton>}

                {Info.codeSandBox && <IconButton href={Info.codeSandBox} target='_blank'>
                    <CodeIcon sx={{ color: color.lightSlate }} />
                </IconButton>}

                {Info.instagram && <IconButton href={Info.instagram} target='_blank'>
                    <InstagramIcon sx={{ color: color.lightSlate }} />
                </IconButton>}

            </div>
        </div>
    )
}

export default LeftPanel
