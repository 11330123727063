import React, { useEffect } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import { Container, Drawer, Divider, List, ListItemIcon, ListItem, ListItemText, Button } from '@mui/material';
import { Clear } from '@mui/icons-material';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MenuData from '../utils/ResumeData'
import { Link } from 'react-scroll'
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from "@mui/material/AppBar";
import color from '../utils/color'
import font from '../utils/font'
import '../App.css';

import Info from '../utils/ResumeData'
export default function Welcome(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    // const handleChange = (event) => {
    //     setAuth(event.target.checked);
    //   };
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open"
    })(({ theme, open }) => ({

        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        ...(open && {
            drawerWidth: (window.innerWidth * 75) / 100,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }),
            marginRight: drawerWidth
        })
    }));

    const DrawerHeader = styled('div')(({ theme }) => ({
        drawerWidth: (window.innerWidth * 75) / 100,
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const drawerWidth = (window.innerWidth * 75) / 100;
    const style = {
        header: {
            height: "20%",
            padding: "5%",
            display: "grid"
        },
        headerText: {
            fontFamily: font.monospace, flexGrow: 1, mr: 3, color: color.whiteLightMenu

        },
        headerTextNumeric: {
            fontFamily: font.monospace, flexGrow: 1, color: color.textGreen, mr: 1

        }
    }

    const resumeBtn = <a href='/AmanKumar.pdf' download='AmanKumar_Resume'><Button  className="contactBtn" sx={{ height: "40px", width: "100px", color: color.textGreen}} variant="outlined"> Resume</Button></a> 
    return (
        <Box sx={{ flexGrow: 1, height: "10%", bgcolor: color.backgroundColor, pt: 3, ml: '3%', mr: '3%', position: 'sticky', top: 0, zIndex: 2 }}>
            <AppBar position='sticky' sx={{ top: 0, zIndex: 2, bgcolor: color.backgroundColor, boxShadow: 'none', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                <div style={{ float: 'left' }}>
                    <IconButton
                        size="small"
                        edge="start"
                        onClick={(id) => window.location.reload()}
                        // style={{ backgroundColor: color.textGreen }}
                    >
                        <Avatar alt="Aman Kumar" variant="square" style={{ color: color.textGreen, backgroundColor: color.backgroundColor }} src= {Info.icon} />
                    </IconButton></div>
                <div>
                    {props.isMobile ?
                        <div style={{ float: 'right' }}><IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleDrawerOpen}
                        >

                            <MenuIcon style={{ fontSize: "1.5em", color: color.textGreen }} />
                        </IconButton>
                            <Drawer
                                sx={{
                                    width: '75%',
                                    flexShrink: 0,
                                    '& .MuiDrawer-paper': {
                                        width: '75%',
                                        bgcolor: color.lightBlue,
                                    },
                                }}

                                variant="persistent"
                                anchor="right"

                                open={open}
                            >
                                <DrawerHeader sx={{ padding: 5 }}>
                                    <IconButton onClick={handleDrawerClose}>

                                        <Clear style={{ fontSize: "1.5em", color: color.textGreen }} />
                                    </IconButton>
                                </DrawerHeader>

                                <List style={{ marginTop: "40%" }}>
                                    {MenuData.menu.map((text, index) => (
                                        <ListItem button key={text} style={{ flexDirection: 'column', fontFamily: font.monospace }}>
                                            <ListItemText primary={'0' + (index + 1) + '.'} sx={{ color: color.textGreen, fontFamily: font.monospace }} />
                                            <Link onClick={handleDrawerClose} to={text.toLowerCase() === 'expirence' ? 'job' : text.toLowerCase() === 'work' ? 'project' : text.toLowerCase()} spy={true} smooth={true} underline="none" style={{ justifyContent: "right", display: "flex" }} >
                                                <Typography edge="start" variant="h8" component="div" sx={{ color: color.drawerMenuColor, fontFamily: font.monospace }}>
                                                    {text}
                                                </Typography>
                                            </Link>
                                        </ListItem>
                                    ))}
                                    {resumeBtn}
                                </List>
                            </Drawer>

                        </div>
                        :
                        <Container edge="end" style={{ justifyContent: "right", display: "flex",alignItems:'center'}}>
                            {MenuData.menu.map((text, index) => {
                                return <Link key={text} to={text.toLowerCase() === 'expirence' ? 'job' : text.toLowerCase() === 'work' ? 'project' : text.toLowerCase()} spy={true} smooth={true} underline="none" style={{ justifyContent: "right", display: "flex" }} >
                                    <Typography edge="start" variant="h9" component="div" sx={style.headerTextNumeric}>
                                        {'0' + (index + 1) + '.'}
                                    </Typography>
                                    <Typography edge="start" variant="h9" component="div" sx={style.headerText}>
                                        {text}
                                    </Typography>
                                </Link>
                            })}

                           {resumeBtn}
                        </Container>}
                </div>
            </AppBar>
        </Box>
    );
}