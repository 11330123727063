import { Box } from '@mui/material'
import React from'react'
import { Profiler } from 'react'
import BasicInfo from './BasicInfo'
import AboutMe from './AboutMe'
import color from '../utils/color'
import Expirence from './Expirence'
import Projects from './Projects'
import Contact from './Contact'
import Footer from './Fotter'
import LeftPanel from './LeftSide';
import RightPanel from './RightSide'
import Tile from './Tile'
function onRenderCallback(
    id, // the "id" prop of the Profiler tree that has just committed
    phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration, // time spent rendering the committed update
    baseDuration, // estimated time to render the entire subtree without memoization
    startTime, // when React began rendering this update
    commitTime, // when React committed this update
    interactions // the Set of interactions belonging to this update
  ) {
    // Aggregate or log render timings... marginLeft:"15%",marginRight:'3%',
    console.log(id, actualDuration)
  }
export default function MainContent (props){
    return(
      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
        {window.innerWidth > 925 &&
      <LeftPanel /> }
   
        <Box style={{   marginLeft: "3%", marginRight: "3%",textAlign:'start', display:'flex',justifyContent:'space-between',flexDirection:'column'}}>
            <Profiler id='BasicInfo' onRender={onRenderCallback}>
            <BasicInfo isMobile={props.isMobile}> </BasicInfo>
            </Profiler>
        
            <Profiler id='AboutMe'  onRender={onRenderCallback}>
            <AboutMe isMobile={props.isMobile}/>
            </Profiler>
            <Profiler id='Expirence'  onRender={onRenderCallback}>
            <Expirence isMobile={props.isMobile}/>
            </Profiler>
            <Profiler id='Projects'  onRender={onRenderCallback}>
            <Projects isMobile={props.isMobile} />
            </Profiler>
            <Profiler id='Contact'  onRender={onRenderCallback}>
            <Contact isMobile={props.isMobile} />
            </Profiler>
            {/* <Tile /> */}
            <footer>
          <Footer isMobile={props.isMobile}/> 
        </footer>
        </Box>
        {window.innerWidth > 925 &&
         <RightPanel /> }
     
        </div>
    )
}