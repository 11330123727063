import { Button, Typography } from '@mui/material'
import React from 'react'
import  color from '../utils/color';
import  font from '../utils/font';
import '../utils/Main.css'
import  Info from '../utils/ResumeData'
import PhoneIcon from '@mui/icons-material/Phone';
const Link = <a href="mailto:someone@example.com">Link</a>
function Contact(props) {
    return (
        <div id="contact" style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop:  props.isMobile? '40%':'20%' }}>

            <Typography edge="start" variant="h6" component="div" sx={{ color: color.lightSlate, fontSize: 15,pt:20 }}>
                <span style={{ color: color.textGreen, fontFamily: font.monospace}}>04.</span> What's Next?
            </Typography>

            <Typography edge="start" variant="h6" component="div" sx={{ color: color.textWhite, fontSize: 35 }}>
                {Info.contactHeader}
            </Typography>
            <div style={{ textAlign: 'center' ,width: props.isMobile ? '100%':'50%'}} >
                <Typography edge="start" variant="h8" component="div" sx={{ color: color.lightestBlue,  alignItems: 'center' }}>
                   {Info.contact}
                </Typography>
            </div>
            <span style={{color:color.lightestBlue}}><PhoneIcon color='white' sx={{color:color.textdullWhite}} /> {Info.mobileNo}</span>
            <Button  href={"mailto:"+ Info.email} className="contactBtn" sx={{mt:10,height:"50px", width:"150px",color:color.lightestBlue}}variant="outlined"> {Info.emailbtn}</Button>
        </div>
    )
}

export default Contact
