import React, { useEffect } from 'react'
import  color from '../utils/color';
import '../utils/Main.css'
import { Typography,  Grid, Paper, Container } from '@mui/material';
import ProjectDetails from './ProjectDetails';
import  Info from '../utils/ResumeData'
import  font from '../utils/font'
import { experimentalStyled as styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
  }));

export default function Projects() {
    return (
        <div id="project" style={{ marginTop: '5%' }}>

<Typography  variant="h6" component="div" sx={{ color: color.textWhite,textAlign:'center',pt:20 }}>
                <span style={{ color: color.textGreen, fontFamily: font.monospace }}>03.</span>Projects I Have worked on
            </Typography>

            <Container sx={{ flexGrow: 1,pt:5,display:'flex',alignContent:'center', flexDirection:'column' }}>
           
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    { Info.projectDetails.map((projects) => (
                    <Grid item xs={6} sm={6} md={6} key={projects.name}>
                        <Item>
                        <ProjectDetails key={projects.name} name={projects.name} description={projects.description} language={projects.language}/>
                        </Item>
                    </Grid>
                     ))} 
                </Grid>
            </Container>
        </div>
    )
}
