import { Link, Typography } from '@mui/material'
import React from 'react'
import  color from '../utils/color'
import '../utils/Main.css'
import  Info from '../utils/ResumeData'
import  font from '../utils/font'

function RightPanel() {
    return (
        <div className='horizontalLine ' style={{color:color.lightSlate, width:'35%',textAlign:'right',writingMode:'vertical-rl'}}>
           <Link  color= {color.lightSlate} underline="none" href={"mailto:"+Info.email}className="sticky" sx={{fontFamily:font.monospace}}>
           {Info.email}
           </Link>          
        </div>
    )
}

export default RightPanel
