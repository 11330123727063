import './App.css';
import Content from './Component/MainContent';
import Header from './Component/Header'

import React from 'react';
import CssBaseline from "@mui/material/CssBaseline";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    }
  }
 

  update = () => {
    if (window.innerWidth < '757') {
      console.log("true")
      this.setState({
        isMobile: true
      });
    }
    else
      this.setState({
        isMobile: false
      });

  };
  componentDidMount() {
    window.addEventListener("resize", this.update);
  }
  render() {
    return (
      <div className="App" >
        <CssBaseline />
        <Header isMobile={(window.innerWidth<757)? true:false} />
        <Content  isMobile={(window.innerWidth<757)? true:false} />
      </div>
      
    );
  }
}

export default App;
