import { Box, Container, Typography } from '@mui/material';
import React,{useEffect} from 'react';
import  Info from '../utils/ResumeData'
import  color from '../utils/color'
import  font from '../utils/font'

var style={
    headerText: {
        fontFamily:  font.monospace,  mr: 3, color: color.textGreen,pt:20,
 },
 headerTextinfoName: {
    fontFamily: font.sans, flexGrow: 1, mr: 3, color: color.textWhite,fontWeight: 'bold', paddingTop:'1%'
},
 headerTextinfo: {
    fontFamily: font.sans, mr: 3, color: color.textdullWhite,fontWeight: 'bold', paddingTop:'1%'
}
 
}

class BasicInfo extends React.Component {

    constructor(){
        super(); 
    }
    static nameFontSize= ((window.innerWidth/125)+1)+'vh'
    static nameFontSizeInfo= ((window.innerWidth/130))+'vh'
  
    render() {
        this.nameFontSize= (this.props.isMobile)?((window.innerWidth/120)+1)+'vh' : ((window.innerWidth/120)+1)+'vh' //"5em"
        this.nameFontSizeInfo=  (this.props.isMobile)?((window.innerWidth/130))+'vh':  ((window.innerWidth/22)+1)
        return (
            <Box style={{ height: (this.props.isMobile) ? '100%':window.innerHeight-200+'px' ,display:'inline-flex',flexDirection:'column',justifyContent:'center'}}>
                <Typography edge="start" variant="h6" component="div" sx={style.headerText}>
                    Hi, my name is
                </Typography>
                <Typography edge="start" variant="h2" component="div" sx={{fontFamily: font.sans,fontSize: this.nameFontSize, mr: 3, color: color.textWhite,fontWeight: 'bold', paddingTop:'1%'}}>
                    {Info.name}
                </Typography>
                <Typography edge="start" variant="h2" component="div" sx={style.headerTextinfo} style={{fontSize:this.nameFontSizeInfo , width:"100%"}}>
                    {Info.phrase}
                </Typography>
                <Typography edge="start" variant="p" component="div" sx={style.headerTextinfo} style={{lineHeight:'1.8', width:(this.props.isMobile)?"100%":"50%"}}>
               {Info.briefInfo}
              <a href={Info.companyURL} style={{fontFamily: font.sans, color: color.textGreen, textDecoration:'none'}}  underline="none" target="_blank" rel="noreferrer">{Info.companyName}</a>
                </Typography>
            </Box>
        )
    }

}

export default BasicInfo;